// This will hold the context provider
import { Link } from "gatsby";
import React from "react";
import ContentContainer from "../container/ContentContainer";

const TitleDivNew = ({ parentText, title, className, path }) => {
  return (
    <ContentContainer
      containerClassName={`bg-gray2 py-5 div-flex ${className}`}
      className="flex flex-col lg:flex-row items-center justify-center lg:justify-start"
    >
      <div className="text-2xl sm:text-4xl mr-2 font-raleway">
        <Link className="button-action" to="/">
          <strong>NWP</strong>
        </Link>
        {` `}
      </div>

      <div>
        <h1 className="text-2xl sm:text-4xl text-center md:text-left font-raleway">
          {parentText ? (
            <Link className="button-action" to={"../".repeat(path?.length)}>
              {parentText.toUpperCase()}
            </Link>
          ) : (
            title.toUpperCase()
          )}
        </h1>
      </div>
    </ContentContainer>
  );
};

export default TitleDivNew;
