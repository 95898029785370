import React from "react";
import Header from "../components/sections/Header";
import HeadData from "../data/HeadData";
import ContentContainer from "../components/container/ContentContainer";
import ContactExpert from "../components/controls/ContactExpert";
import TitleDivNew from "../components/controls/TitleDivNew";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout/Layout";

const AboutPage = () => {
  const title = "Wholesale Wood Products - So Cal";
  const tree = useStaticQuery(graphql`
    {
      image: file(relativePath: { eq: "tree.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            height: 200
            quality: 100
          )
        }
      }
    }
  `);

  const image = getImage(tree.image);

  return (
    <Layout>
      <div className="w-full max-w-full relative overflow-hidden">
        <HeadData 
          title={title}
          description="Our wholesale wood products offer the best quality of hardwood to meet all your needs, from lumber and decking to furniture and decorative accents. Talk to us!"
        />
        <Header />
        <TitleDivNew parentText={title} />
        <ContentContainer
          containerClassName="div-flex"
          className="flex-col md:flex-row gap-x-10 gap-y-4 w-full max-w-6xl py-11"
        >
          <div className="flex-grow">
            <GatsbyImage
              as="div"
              image={image}
              objectFit="contain"
              className="w-full h-full"
              alt="Peview"
            />
          </div>
          <div className="px-4 sm:px-0 md:w-7/12">
            <div className="flex flex-col justify-center items-center md:items-start text-left leading-relaxed">
              <p>
                National Wood Products, Inc. opened its doors in 1984 in Salt Lake
                City, Utah as a lumber distributor. We had one ’56 Hyster
                Forklift, a used delivery truck, 5,000 square feet of warehouse
                space and 12,000 board feet of hardwood lumber. Things have
                changed a bit since that time.
              </p>
              <br />
              <p>
                One thing that hasn’t changed is the vision to carve a niche in a
                competitive market by providing quality products and top notch
                customer service. National Wood Products has grown into a multi
                product, multi location Hardwood Lumber and Plywood Distributor.
              </p>
              <br />
              <p>
                Throughout this growth, we haven’t lost sight of how we got here
                and how valuable our customers, manufacturers, and mills are to
                us! These relationships are what make National Wood Products
                unique.
              </p>
              <br />
              <p>
                National Wood Products, Inc. has been in business for over 35
                years and is a subsidiary of{" "}
                <a
                  href="https://www.baillie.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Baillie Lumber
                </a>
                , one of the largest Hardwood conglomerates in the United States.
              </p>
              <br />
              <p>
                We are proud to represent some of the most highly respected
                Hardwood lumber mills and plywood manufacturers in North America.
              </p>
              <br />
              <p>
                With locations in Southern California, Northern California,
                Denver, and Salt Lake City we have the western U.S. covered.
                Between these four locations, we encompass over 500,000 square
                feet of indoor warehouse space, enabling us to carry the right
                product mix for our customers.
              </p>
            </div>
          </div>
        </ContentContainer>

        <ContactExpert />
      </div>
    </Layout>
  );
};

export default AboutPage;
